var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-dialog',{attrs:{"maximized":"","position":_vm.isMobile ? 'right' : 'bottom'},on:{"show":_vm.onShow,"hide":_vm.onHide},model:{value:(_vm.dialogState),callback:function ($$v) {_vm.dialogState=$$v},expression:"dialogState"}},[_c('q-card',{staticClass:"addItemsDialogue"},[_c('div',{staticClass:"addItemsContainer"},[(!_vm.isMobile)?_c('div',{staticClass:"addItemsLeft"},[_c('div',{staticClass:"addItemsLeftBack"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"no-caps":"","unelevated":"","flat":""}},[_c('SvgIcon',{staticClass:"q-mr-md",attrs:{"icon":"back-arrow-line"}}),_vm._v("Back\n          ")],1)],1),_c('q-scroll-area',{staticClass:"addItemsHeightLeft",staticStyle:{"max-width":"100%"},attrs:{"thumb-style":_vm.scrollAreaThumbStyle,"bar-style":_vm.scrollAreaBarStyle,"visible":_vm.scrollAreaVisible}},[(_vm.isOpen)?_c('q-tree',{ref:"QTree",staticClass:"filterCategory",attrs:{"nodes":_vm.categories,"expandable-key":"hasChild","children-key":"childCategory","node-key":"categoryID","selected-color":"primary","selected":_vm.selectedCategoryId,"accordion":"","no-connectors":""},on:{"update:selected":_vm.selectNode},scopedSlots:_vm._u([{key:"default-header",fn:function(prop){return [_c('q-item',{staticClass:"items-center",attrs:{"tag":"label","v-ripple":false}},[_c('q-item-section',{attrs:{"avatar":"","top":""}},[_c('q-radio',{attrs:{"dense":"","val":prop.node.categoryID},on:{"input":function($event){return _vm.refreshListing()}},model:{value:(_vm.selectedCategoryId),callback:function ($$v) {_vm.selectedCategoryId=$$v},expression:"selectedCategoryId"}})],1),_c('q-item-section',{class:{
                    'text-primary': _vm.expandedKeys.includes(
                      prop.node.categoryID
                    ),
                  }},[_c('q-item-label',[_vm._v(_vm._s(_vm.$options.filters.titleCase(prop.node.categoryName)))])],1)],1)]}}],null,false,3532562597)}):_vm._e()],1)],1):_vm._e(),_c('div',{staticClass:"addItemsRight"},[_c('div',{staticClass:"addItemsHeader"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"close",attrs:{"unelevated":"","flat":"","round":"","icon":"close"}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3 col-xl-4"},[_c('h3',[_vm._v("Add more items")])]),_c('div',{staticClass:"col-12 col-md-9 col-xl-8"},[_c('div',{staticClass:"row q-col-gutter-sm"},[_c('div',{staticClass:"col-2 lt-md"},[_c('q-btn',{staticClass:"full-width q-py-sm q-aifilter-xs",attrs:{"unelevated":"","color":"primary","icon":"filter_list"},on:{"click":function($event){return _vm.changeDialogState({
                        dialog: 'AddOpenCategory',
                        val: true,
                        parentDialog: 'AddOrderItems',
                      })}}},[(_vm.selectedCategoryId)?_c('q-badge',{attrs:{"color":"red","floating":""}}):_vm._e()],1)],1),_c('div',{staticClass:"col-10 col-sm-5 col-md-6 col-lg-8"},[_c('q-input',{attrs:{"debounce":"500","placeholder":"Search here","outlined":""},on:{"input":_vm.refreshListing},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"search"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.search && _vm.search.trim()),expression:"search && search.trim()"}],attrs:{"dense":"","flat":"","ripple":false,"icon":"close","size":".5em"},on:{"click":_vm.clearSearch}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-12 col-sm-5 col-md-6 col-lg-4 relative-position"},[_c('div',[_c('q-btn',{staticClass:"q-pa-xs q-px-sm full-width btnadd",attrs:{"unelevated":"","color":"primary","disable":Object.keys(_vm.recentItems).length ? false : true},on:{"click":_vm.AddItemInOrder}},[_vm._v("\n                      Add to order\n                      "),_c('span',{staticClass:"txt",domProps:{"innerHTML":_vm._s(`${_vm.recentItemsSummary.count} item selected`)}})]),_c('div',{class:[
                        'arrowItemDropdown',
                        _vm.showMobileMiniCart && Object.keys(_vm.recentItems).length
                          ? 'active'
                          : '',
                      ]},[_c('span',{class:Object.keys(_vm.recentItems).length ? '' : 'disable',on:{"click":function($event){_vm.showMobileMiniCart = !_vm.showMobileMiniCart}}},[_vm._v(" ")]),_c('q-list',{staticClass:"rounded-borders addItemsOrders sidebarCartAccordion edit-order-add",attrs:{"padding":""}},[_c('q-list',{staticClass:"cartList"},[_c('h3',[_c('SvgIcon',{staticClass:"q-mr-md",attrs:{"color":"primary","icon":_vm.codeName}}),_vm._v("\n                            "+_vm._s(_vm.displayName)+" ("+_vm._s(_vm.recentItemsSummary.count)+")\n                          ")],1),_vm._l((_vm.recentItems),function(item,index){return _c('q-card',{key:index,class:[
                              'my-card',
                              { 'edit-order-item': item.linkedProduct },
                            ],attrs:{"flat":""}},[_c('q-item',{staticClass:"item-start"},[_c('router-link',{staticClass:"no-underline",attrs:{"to":{
                                  name: 'Detail',
                                  params: { seoName: item.seoName },
                                },"title":item.title}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-img',{attrs:{"src":_vm._f("defaultProductImage")(item.profileImage),"ratio":1}})],1)],1),_c('q-item-section',[_c('q-item-label',{staticClass:"product-title ellipsis-2-lines"},[_c('router-link',{staticClass:"no-underline",attrs:{"to":{
                                      name: 'Detail',
                                      params: { seoName: item.seoName },
                                    },"title":item.title}},[_vm._v("\n                                    "+_vm._s(item.title)+"\n                                  ")])],1),_c('q-item-label',{staticClass:"row no-wrap items-center",attrs:{"caption":""}},[_c('OrderCartButton',{attrs:{"productId":item.productID,"product":item,"updateState":true}}),_c('q-item-label',{staticClass:"col-5 q-ml-sm product-qty"},[_vm._v("\n                                    X "+_vm._s(_vm._f("currency")(item.price))+"\n                                  ")])],1)],1),_c('q-item-section',{staticClass:"cartProductSide",attrs:{"side":""}},[_c('div',{staticClass:"cartItemRemove"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$store.commit(
                                        'order/DELETE_RECENT_ITEM',
                                        item.productID
                                      )}}},[_c('SvgIcon',{attrs:{"icon":"trash"}})],1)]),_c('q-item-label',{staticClass:"product-price",domProps:{"innerHTML":_vm._s(
                                    _vm.$options.filters.currency(
                                      item.qty * item.price
                                    )
                                  )}})],1),(item && item.linkedProduct)?_c('div',{staticClass:"linked-product"},[_c('q-item',{staticClass:"item-start"},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-img',{attrs:{"src":_vm._f("defaultProductImage")(item.linkedProduct.profileImage),"width":"50px"}})],1),_c('q-item-section',{},[_c('q-item-label',{staticClass:"ellipsis"},[_c('strong',[_vm._v("\n                                        "+_vm._s(item.linkedProduct.title))]),_c('q-item-label',{staticClass:"q-mt-xs text-grey"},[_vm._v("\n                                        "+_vm._s(item.linkedProduct.linkedQty *
                                          item.qty)+"\n                                        x\n                                        "),_c('span',{staticClass:"q-mr-sm"},[_vm._v("\n                                          "+_vm._s(_vm._f("currency")(item.linkedProduct.price))+"\n                                        ")])])],1),_c('q-item-label',{staticClass:"product-price q-mt-sm",domProps:{"innerHTML":_vm._s(
                                        _vm.$options.filters.currency(
                                          item.linkedProduct.linkedQty *
                                            item.qty *
                                            item.linkedProduct.price
                                        )
                                      )}})],1)],1)],1):_vm._e()],1)],1)})],2),_c('div',{staticClass:"addItemsOrdersBottom"},[_c('p',{staticClass:"text-uppercase"},[_vm._v("\n                            Subtotal:\n                            "),_c('strong',{staticClass:"text-primary",domProps:{"innerHTML":_vm._s(
                                _vm.$options.filters.currency(
                                  _vm.recentItemsSummary.price
                                )
                              )}})])])],1)],1)],1)])])])])],1),_c('div',{staticClass:"page-content addItemsHeight"},[_c('InnerLoadingSpinner',{attrs:{"showing":!_vm.isOpen}}),_c('q-scroll-area',{ref:"productListing",staticClass:"addItemsHeight",staticStyle:{"max-width":"100%"},attrs:{"thumb-style":_vm.scrollAreaThumbStyle,"bar-style":_vm.scrollAreaBarStyle,"visible":_vm.scrollAreaVisible}},[(_vm.isOpen)?[_c('q-infinite-scroll',{ref:"infiniteScroll",staticClass:"product-items",attrs:{"debounce":"500","offset":500},on:{"load":_vm.fetchNext},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('LoadMoreSpinner',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showListingSkeleton),expression:"!showListingSkeleton"}]}),_c('ProductSkeleton',{directives:[{name:"show",rawName:"v-show",value:(_vm.showListingSkeleton),expression:"showListingSkeleton"}],attrs:{"size":12}})]},proxy:true}],null,false,3670264695)},_vm._l((_vm.products),function(product,key){return _c('ProductCard',{key:key,attrs:{"product":product,"addToCartButton":"order","parentDialog":"AddOrderItems"}})}),1),(!_vm.loading && Object.keys(_vm.products).length == 0)?_c('EmptyComponent',{attrs:{"imgWidth":180,"image":`images/empty-product.png`}},[_c('p',[_vm._v("No products found")])]):_vm._e()]:_vm._e()],2)],1)])])]),(_vm.isMobile)?_c('AddOpenCategory'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }