<template>
  <q-dialog
    v-model="dialogState"
    maximized
    :position="isMobile ? 'right' : 'bottom'"
    @show="onShow"
    @hide="onHide"
  >
    <q-card class="addItemsDialogue">
      <div class="addItemsContainer">
        <div class="addItemsLeft" v-if="!isMobile">
          <div class="addItemsLeftBack">
            <q-btn no-caps unelevated flat v-close-popup>
              <SvgIcon icon="back-arrow-line" class="q-mr-md" />Back
            </q-btn>
          </div>
          <q-scroll-area
            class="addItemsHeightLeft"
            :thumb-style="scrollAreaThumbStyle"
            :bar-style="scrollAreaBarStyle"
            :visible="scrollAreaVisible"
            style="max-width: 100%"
          >
            <q-tree
              v-if="isOpen"
              :nodes="categories"
              expandable-key="hasChild"
              children-key="childCategory"
              node-key="categoryID"
              selected-color="primary"
              :selected="selectedCategoryId"
              accordion
              no-connectors
              ref="QTree"
              class="filterCategory"
              @update:selected="selectNode"
            >
              <template v-slot:default-header="prop">
                <q-item tag="label" :v-ripple="false" class="items-center">
                  <q-item-section avatar top>
                    <q-radio
                      dense
                      v-model="selectedCategoryId"
                      :val="prop.node.categoryID"
                      @input="refreshListing()"
                    />
                  </q-item-section>
                  <q-item-section
                    :class="{
                      'text-primary': expandedKeys.includes(
                        prop.node.categoryID
                      ),
                    }"
                  >
                    <q-item-label>{{ $options.filters.titleCase(prop.node.categoryName) }}</q-item-label>
                  </q-item-section>
                </q-item>
              </template>
            </q-tree>
          </q-scroll-area>
        </div>
        <div class="addItemsRight">
          <div class="addItemsHeader">
            <q-btn
              unelevated
              flat
              round
              icon="close"
              v-close-popup
              class="close"
            />
            <div class="row">
              <div class="col-12 col-md-3 col-xl-4">
                <h3>Add more items</h3>
              </div>
              <div class="col-12 col-md-9 col-xl-8">
                <div class="row q-col-gutter-sm">
                  <div class="col-2 lt-md">
                    <q-btn
                      unelevated
                      color="primary"
                      icon="filter_list"
                      class="full-width q-py-sm q-aifilter-xs"
                      @click="
                        changeDialogState({
                          dialog: 'AddOpenCategory',
                          val: true,
                          parentDialog: 'AddOrderItems',
                        })
                      "
                    >
                      <q-badge v-if="selectedCategoryId" color="red" floating />
                    </q-btn>
                  </div>
                  <div class="col-10 col-sm-5 col-md-6 col-lg-8">
                    <q-input
                      debounce="500"
                      placeholder="Search here"
                      v-model="search"
                      outlined
                      @input="refreshListing"
                    >
                      <template v-slot:prepend>
                        <q-icon name="search" />
                      </template>
                      <template v-slot:append>
                        <q-btn
                          dense
                          flat
                          :ripple="false"
                          icon="close"
                          size=".5em"
                          @click="clearSearch"
                          v-show="search && search.trim()"
                        />
                      </template>
                    </q-input>
                  </div>
                  <div
                    class="col-12 col-sm-5 col-md-6 col-lg-4 relative-position"
                  >
                    <div>
                      <q-btn
                        unelevated
                        color="primary"
                        class="q-pa-xs q-px-sm full-width btnadd"
                        :disable="
                          Object.keys(recentItems).length ? false : true
                        "
                        @click="AddItemInOrder"
                      >
                        Add to order
                        <span
                          class="txt"
                          v-html="`${recentItemsSummary.count} item selected`"
                        />
                      </q-btn>
                      <div
                        :class="[
                          'arrowItemDropdown',
                          showMobileMiniCart && Object.keys(recentItems).length
                            ? 'active'
                            : '',
                        ]"
                      >
                        <span
                          :class="
                            Object.keys(recentItems).length ? '' : 'disable'
                          "
                          @click="showMobileMiniCart = !showMobileMiniCart"
                          >&nbsp;</span
                        >
                        <!-- -->
                        <q-list
                          padding
                          class="rounded-borders addItemsOrders sidebarCartAccordion edit-order-add"
                        >
                          <q-list class="cartList">
                            <h3>
                              <SvgIcon
                                color="primary"
                                :icon="codeName"
                                class="q-mr-md"
                              />
                              {{ displayName }} ({{ recentItemsSummary.count }})
                            </h3>

                            <q-card
                              v-for="(item, index) in recentItems"
                              :key="index"
                              :class="[
                                'my-card',
                                { 'edit-order-item': item.linkedProduct },
                              ]"
                              flat
                            >
                              <q-item class="item-start">
                                <router-link
                                  class="no-underline"
                                  :to="{
                                    name: 'Detail',
                                    params: { seoName: item.seoName },
                                  }"
                                  :title="item.title"
                                >
                                  <q-item-section avatar>
                                    <q-img
                                      :src="
                                        item.profileImage | defaultProductImage
                                      "
                                      :ratio="1"
                                    />
                                  </q-item-section>
                                </router-link>
                                <q-item-section>
                                  <q-item-label
                                    class="product-title ellipsis-2-lines"
                                  >
                                    <router-link
                                      class="no-underline"
                                      :to="{
                                        name: 'Detail',
                                        params: { seoName: item.seoName },
                                      }"
                                      :title="item.title"
                                    >
                                      {{ item.title }}
                                    </router-link>
                                  </q-item-label>
                                  <q-item-label
                                    class="row no-wrap items-center"
                                    caption
                                  >
                                    <OrderCartButton
                                      :productId="item.productID"
                                      :product="item"
                                      :updateState="true"
                                    />
                                    <q-item-label
                                      class="col-5 q-ml-sm product-qty"
                                    >
                                      X {{ item.price | currency }}
                                    </q-item-label>
                                  </q-item-label>
                                </q-item-section>

                                <q-item-section class="cartProductSide" side>
                                  <div class="cartItemRemove">
                                    <a
                                      href="javascript:void(0)"
                                      @click="
                                        $store.commit(
                                          'order/DELETE_RECENT_ITEM',
                                          item.productID
                                        )
                                      "
                                    >
                                      <SvgIcon icon="trash" />
                                    </a>
                                  </div>
                                  <q-item-label
                                    class="product-price"
                                    v-html="
                                      $options.filters.currency(
                                        item.qty * item.price
                                      )
                                    "
                                  />
                                </q-item-section>

                                <!-- Linked Product -->
                                <div
                                  class="linked-product"
                                  v-if="item && item.linkedProduct"
                                >
                                  <q-item class="item-start">
                                    <q-item-section avatar>
                                      <q-img
                                        :src="
                                          item.linkedProduct.profileImage
                                            | defaultProductImage
                                        "
                                        width="50px"
                                      />
                                    </q-item-section>

                                    <q-item-section class="">
                                      <q-item-label class="ellipsis">
                                        <strong>
                                          {{ item.linkedProduct.title }}</strong
                                        >
                                        <q-item-label class="q-mt-xs text-grey">
                                          {{
                                            item.linkedProduct.linkedQty *
                                            item.qty
                                          }}
                                          x
                                          <span class="q-mr-sm">
                                            {{
                                              item.linkedProduct.price
                                                | currency
                                            }}
                                          </span>
                                        </q-item-label>
                                      </q-item-label>

                                      <q-item-label
                                        class="product-price q-mt-sm"
                                        v-html="
                                          $options.filters.currency(
                                            item.linkedProduct.linkedQty *
                                              item.qty *
                                              item.linkedProduct.price
                                          )
                                        "
                                      />
                                    </q-item-section>
                                  </q-item>
                                </div>
                                <!-- End Linked Product -->
                              </q-item>
                            </q-card>
                          </q-list>
                          <div class="addItemsOrdersBottom">
                            <p class="text-uppercase">
                              Subtotal:
                              <strong
                                class="text-primary"
                                v-html="
                                  $options.filters.currency(
                                    recentItemsSummary.price
                                  )
                                "
                              />
                            </p>
                          </div>
                        </q-list>
                      </div>
                      <!-- -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="page-content addItemsHeight">
            <InnerLoadingSpinner :showing="!isOpen" />
            <q-scroll-area
              class="addItemsHeight"
              :thumb-style="scrollAreaThumbStyle"
              :bar-style="scrollAreaBarStyle"
              :visible="scrollAreaVisible"
              style="max-width: 100%"
              ref="productListing"
            >
              <template v-if="isOpen">
                <q-infinite-scroll
                  debounce="500"
                  ref="infiniteScroll"
                  class="product-items"
                  @load="fetchNext"
                  :offset="500"
                >
                  <ProductCard
                    v-for="(product, key) in products"
                    :key="key"
                    :product="product"
                    addToCartButton="order"
                    parentDialog="AddOrderItems"
                  />
                  <template v-slot:loading>
                    <LoadMoreSpinner v-show="!showListingSkeleton" />
                    <ProductSkeleton :size="12" v-show="showListingSkeleton" />
                  </template>
                </q-infinite-scroll>

                <EmptyComponent
                  :imgWidth="180"
                  v-if="!loading && Object.keys(products).length == 0"
                  :image="`images/empty-product.png`"
                >
                  <p>No products found</p>
                </EmptyComponent>
              </template>
            </q-scroll-area>
          </div>
        </div>
      </div>
    </q-card>
    <AddOpenCategory v-if="isMobile" />
  </q-dialog>
</template>

<script>
import OrderCartButton from 'components/OrderCartButton'
import ProductCard from 'components/ProductCard'
import ProductSkeleton from 'components/category/ProductSkeleton'
import AddOpenCategory from 'components/order/AddOpenCategory'
import { convertArrayToObject } from 'src/utils/index'
import { mapGetters } from 'vuex'

function initialState() {
  return {
    selectedCategoryId: '',
    showListingSkeleton: false,
    products: {},
    loading: false,
    search: '',
    timer: null,
    showMobileMiniCart: false,
    isOpen: false,
  }
}
export default {
  name: 'AddOrderItemDialog',
  props: {
    orderId: {
      type: String,
      default: '',
    },
    codeName: {
      type: String,
      default: '',
    },
    displayName: {
      type: String,
      default: '',
    },
  },
  components: {
    ProductCard,
    OrderCartButton,
    AddOpenCategory,
    ProductSkeleton,
  },
  data: function () {
    return initialState()
  },
  computed: {
    ...mapGetters('showcase', ['getDialogState']),
    ...mapGetters('order', ['categories', 'recentItems']),
    ...mapGetters('category', ['getCategoryTreeInfoById', 'searchTree']),
    dialogState: {
      get() {
        return this.getDialogState('AddOrderItems')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'AddOrderItems',
          val,
        })
      },
    },
    expandedKeys() {
      let searchTree
      if (this.selectedCategoryId)
        searchTree = this.getCategoryTreeInfoById(this.selectedCategoryId)
      return searchTree ? searchTree.parentsOfSelected : []
    },
    objListProdctsRequest() {
      let reqObj = {
        OrderId: this.orderId,
        PageSize: 20,
        SearchKeyword: this.search,
      }

      if (this.selectedCategoryId)
        reqObj['CategoryIds'] = this.selectedCategoryId

      return reqObj
    },
    recentItemsSummary() {
      let count = 0,
        price = 0

      for (let key in this.recentItems) {
        if (this.recentItems[key].qty) {
          count +=
            parseInt(this.recentItems[key].qty) +
            parseInt(this.recentItems[key].qty) *
              parseInt(
                this.recentItems[key].linkedProduct
                  ? this.recentItems[key].linkedProduct.linkedQty
                  : 0
              )
          price +=
            parseInt(this.recentItems[key].qty) *
              parseFloat(this.recentItems[key].price) +
            parseFloat(
              this.recentItems[key].linkedProduct
                ? parseInt(this.recentItems[key].qty) *
                    parseInt(
                      this.recentItems[key].linkedProduct
                        ? this.recentItems[key].linkedProduct.linkedQty
                        : 0
                    ) *
                    this.recentItems[key].linkedProduct.price
                : 0
            )
        }
      }

      price = price.toFixed(2)

      return {
        count,
        price,
      }
    },
    objAddItemRequest() {
      let objRequest = {
        orderID: this.orderId,
        editOrderLineItems: [],
      }

      for (let key in this.recentItems) {
        let item = this.recentItems[key]

        objRequest.editOrderLineItems.push({
          productID: item.productID,
          qty: item.qty,
          allowSubstitute: false,
          isDeleted: false,
        })
      }
      return objRequest
    },
  },
  methods: {
    selectNode(val) {
      if (val !== null) {
        this.selectedCategoryId = val
        this.refreshListing()
      }
      return
    },
    onHide() {
      Object.assign(this.$data, initialState())
      this.$store.commit('order/RESET_RECENT_ITEM')
    },
    onShow() {
      setTimeout(() => {
        this.isOpen = true
      }, 100)
    },
    fetchNext(index, done) {
      let params = {
        Page: index,
        ...this.objListProdctsRequest,
      }

      if (index == 1) {
        this.showListingSkeleton = true
        this.products = {}
      }
      this.loading = true

      this.$store.dispatch('order/getProducts', params).then((data) => {
        this.loading = false
        this.showListingSkeleton = false

        if (!data) {
          return
        }

        if (data.items.length) {
          if (index == 1) {
            this.products = Object.assign(
              {},
              convertArrayToObject(data.items, 'productID')
            )
            this.scrollToPosition(this.$refs.productListing, 0, 100)
          } else {
            this.products = Object.assign(
              {},
              this.products,
              convertArrayToObject(data.items, 'productID')
            )
          }
        }

        done(!data.hasNext)
      })
    },
    refreshListing() {
      this.showMobileMiniCart = false
      if (this.$refs && this.$refs.infiniteScroll) {
        this.loading = true
        this.$refs.infiniteScroll.stop()
        this.$refs.infiniteScroll.reset()
        this.$refs.infiniteScroll.resume()
        this.$refs.infiniteScroll.trigger()
        this.$root.$emit('scroll-to-position')
      }
    },
    AddItemInOrder() {
      this.$store
        .dispatch('order/updateOrder', this.objAddItemRequest)
        .then((response) => {
          if (response) {
            this.$store.commit('order/RESET_RECENT_ITEM')
            this.dialogState = false
          }
          else{
            if (error.errors && error.errors.length) {
              error.errors.forEach((error) => {
                if (error.errorCode == 'CRT_0114') {
                  this.ftxConfirmCoupon(
                    '',
                    error.descreption,
                    false,
                    'Ok',
                    true,
                    false,
                    'info-filled',
                    '3em',
                    true,
                    false,
                    false,
                    true
                  )
                }
              })
            }
          }
        })
        .catch((descreption) => {
          this.ftxConfirmCoupon(
                    '',
                    descreption,
                    false,
                    'Ok',
                    true,
                    false,
                    'info-filled',
                    '3em',
                    true,
                    false,
                    false,
                    true
                  );
        })
    },
    clearSearch() {
      this.search = ''
      this.refreshListing()
    },
  },
  created() {
    this.$root.$on('order-edit-category-selected', (categoryId) => {
      if (categoryId !== this.selectedCategoryId) {
        this.selectedCategoryId = categoryId
        this.refreshListing()
      }
    })
  },
}
</script>
