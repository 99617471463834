<template>
  <q-dialog v-model="dialogState" maximized position="bottom" @show="onShow">
    <q-card style="min-height: 80vh" class="mobileCardDialogue">
      <div class="mobileDialogTitle">
        <h5 class="text-h5">Choose Category</h5>
        <q-icon class="close cursor-pointer" name="close" v-close-popup />
      </div>
      <div class="mobileDialogContent" :style="isMobile?'padding-top: 10px; padding-bottom: 10px; padding-right: 10px;':''">
        <InnerLoadingSpinner :showing="!isOpen" />
        <q-scroll-area
          :thumb-style="scrollAreaThumbStyle"
          :bar-style="scrollAreaBarStyle"
          :visible="scrollAreaVisible"
          style="max-width: 100%; height: calc(80dvh - 60px)">
          <q-tree
            v-if="isOpen"
            :nodes="categories"
            expandable-key="hasChild"
            children-key="childCategory"
            node-key="categoryID"
            selected-color="primary"
            :selected="selectedCategoryId"
            accordion
            no-connectors
            ref="QTree"
            class="filterCategory"
            @update:selected="selectNode"
          >
            <template v-slot:default-header="prop">
              <q-item tag="label" :v-ripple="false" class="items-center">
                <q-item-section avatar top>
                  <q-radio
                    dense
                    v-model="selectedCategoryId"
                    :val="prop.node.categoryID"
                    @input="refreshListing()"
                  />
                </q-item-section>
                <q-item-section
                  :class="{
                    'text-primary': expandedKeys.includes(prop.node.categoryID),
                  }"
                >
                  <q-item-label>{{ $options.filters.titleCase(prop.node.categoryName) }}</q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-tree>
        </q-scroll-area>
      </div>
    </q-card>
  </q-dialog>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'AddOpenCategory',
  props: {
    initialCategoryId: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters('showcase', ['getDialogState']),
    ...mapGetters('order', ['categories']),
    ...mapGetters('category', ['getCategoryTreeInfoById']),
    dialogState: {
      get() {
        return this.getDialogState('AddOpenCategory')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'AddOpenCategory',
          val,
        })
      },
    },
    expandedKeys() {
      if (this.selectedCategoryId) {
        let searchTree = this.getCategoryTreeInfoById(this.selectedCategoryId)
        return searchTree.parentsOfSelected
      }
      return []
    },
  },
  data() {
    return {
      isOpen: false,
      selectedCategoryId: this.initialCategoryId,
    }
  },
  methods: {
    onShow() {
      setTimeout(() => {
        this.isOpen = true
      }, 300)
    },
    selectNode(val) {
      if (val !== null) {
        this.selectedCategoryId = val
        this.refreshListing()
      }
      return
    },
    refreshListing() {
      if (this.$root) {
        this.$root.$emit(
          'order-edit-category-selected',
          this.selectedCategoryId
        )
      }
      this.dialogState = false
      return
    },
  },
}
</script>
<style lang="scss">
.mobileDialogContent {
  .filterCategory {
    padding: 0 16px 16px 0;
    .q-tree__node-header {
      padding-left: 0 !important;
    }
    .q-item {
      padding: 0;
      min-height: 31px;
      .q-item__section--avatar {
        min-width: 30px;
        padding-right: 10px;
      }
    }
    .q-tree__arrow {
      order: 2;
    }
    .q-focus-helper {
      display: none;
    }
    .q-tree__node-header {
      padding-left: 0;
    }
  }
}
</style>
